<template>
  <ShowData></ShowData>
</template>

<script>
import ShowData from './components/ShowData.vue'

export default {
  name: 'App',
  components: {
    ShowData
  }
}
</script>

<style>
body, div, #app {font-family: arial; font-size: 12px; margin:0px; padding: 0px;}

body {margin: 10px;}
</style>
