<template>
<div class="dashboard">
  <div>
    <VueDatePicker
        v-model="dateRange"
        range
        :preset-ranges="presetRanges"
        week-start="1"
        locale="de"
        auto-apply format='dd.MM.yyyy'
        month-name-format="long"
        @update:model-value="fetchData()"
    />

    <div class="box sitzungen">
      <div class="label">Sitzungen</div>
      <div class="value">{{ sitzungen }}</div>
    </div>

    <div class="box aufrufe">
      <div class="label">Seiten</div>
      <div class="value">{{ seitenaufrufe }}</div>
    </div>

    <div class="box klickouts">
      <div class="label">Klickouts</div>
      <div class="value">{{ conversions }}</div>
    </div>

    <div class="chartholder" v-if="chartData">
    <Line :data="chartData" :options="chartOptions" />
    </div>


    <h2>Seitenaufrufe</h2>
    <div class="table" v-if="aufgerufene_seiten.length">
      <div class="table-head">
        <div class="table-cell left">Seite</div>
        <div class="table-cell">Aufrufe</div>
        <div class="table-cell">Aufrufe % </div>
        <div class="table-cell">Sitzungen</div>
        <div class="table-cell">Sitzungen % </div>
      </div>
        <div v-for="seite of aufgerufene_seiten" :key="seite" class="table-row">
          <div class="table-cell left">{{ seite.seite }}</div>
          <div class="table-cell">{{ seite.aufrufe }}</div>
          <div class="table-cell">{{ (seite.aufrufe / seitenaufrufe * 100).toFixed(1).replace(".", ",") }} % </div>
          <div class="table-cell">{{ seite.sitzungen }}</div>
          <div class="table-cell">{{ (seite.sitzungen / sitzungen * 100).toFixed(1).replace(".", ",") }} %</div>
        </div>
    </div>
    <div v-else>Keine Daten</div>

    <h2>Zuletzt aufgerufene Seite</h2>
    <div v-if="ausstiegsseiten.length" class="table">
      <div class="table-head">
        <div class="table-cell left">Ausstiegsseite</div>
        <div class="table-cell">Anzahl</div>
        <div class="table-cell">% der Sitzungen </div>
      </div>
      <div v-for="seite of ausstiegsseiten" :key="seite" class="table-row">
        <div class="table-cell left">{{ seite.title }}</div>
        <div class="table-cell">{{ seite.anzahl }}</div>
        <div class="table-cell">{{ (seite.anzahl / sitzungen * 100).toFixed(1).replace(".", ",") }} % </div>
      </div>
    </div>
    <div v-else>
      Keine Daten
    </div>

    <h2>Klicks</h2>
    <div class="table" v-if="buttonklicks.length">
      <div class="table-head">
        <div class="table-cell left">Link</div>
        <div class="table-cell">Anzahl</div>
        <div class="table-cell">% der Sitzungen </div>
      </div>
      <div v-for="seite of buttonklicks" :key="seite" class="table-row">
        <div class="table-cell left">{{ seite.title }}</div>
        <div class="table-cell">{{ seite.anzahl }}</div>
        <div class="table-cell">{{ (seite.anzahl / sitzungen * 100).toFixed(1).replace(".", ",") }} % </div>
      </div>
    </div>
    <div v-else>
      Keine Daten
    </div>
  </div>

  <h2>PLZ-Eingaben</h2>
  <select class="zipcode" v-model="zip_stellen" @change="fetchZipData()">
    <template v-for="n in 5">
      <option v-if="n === 1"  v-bind:key="n + 10" v-bind:value="n">{{ n }} Stelle</option>
      <option v-else v-bind:key="n + 100" v-bind:value="n">{{ n }} Stellen</option>
    </template>
  </select>
  <div class="table zipcode-table" v-if="zipcodeinputs.length">
    <div class="table-head">
      <div class="table-cell zipcode-cell left">PLZ</div>
      <div class="table-cell zipcode-cell">Gesamt</div>
      <div class="table-cell zipcode-cell">Gültig</div>
      <div class="table-cell zipcode-cell">Ungültig</div>
      <div class="table-cell zipcode-cell">Partner</div>
    </div>
    <div v-for="result of zipcodeinputs" :key="result" class="table-row">
      <div class="table-cell zipcode-cell letter-space left">{{ result.zipcode }}</div>
      <div class="table-cell zipcode-cell">{{ result.anzahl }}</div>
      <div class="table-cell zipcode-cell">{{ result.erfolg }} </div>
      <div class="table-cell zipcode-cell">{{ result.misserfolg }} </div>
      <div class="table-cell zipcode-cell">{{ result.partner }} </div>
    </div>
  </div>
  <div v-else>
    Keine Daten
  </div>

</div>
</template>

<script>

import { ref } from 'vue';
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, PointElement,
  LineElement, Title, Tooltip, Legend, CategoryScale, LinearScale } from 'chart.js'
import axios from 'axios'
import { ajaxUrl }  from '../globals.js'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { startOfISOWeek, endOfISOWeek,  subWeeks, subDays,  endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale)

//const date = ref();
const today = new Date();



const presetRanges = ref([
  { label: 'Heute', range: [today, today] },
  { label: 'Letzte 7 Tage',range: [subDays(today, 7), subDays(today, 1)] },
  { label: 'Diese Woche', range: [startOfISOWeek(today), endOfISOWeek(today)]},
  { label: 'Letzte Woche', range: [startOfISOWeek(subWeeks(today, 1)), endOfISOWeek(subWeeks(today, 1))]},
  { label: 'Dieser Monat', range: [startOfMonth(today), endOfMonth(today)] },
  {
    label: 'Letzter Monat',
    range: [startOfMonth(subMonths(today, 1)), endOfMonth(subMonths(today, 1))],
  },
  { label: 'Dieses Jahr', range: [startOfYear(today), endOfYear(today)] },
]);

export default {
  name: 'ShowData',
  components: {
    VueDatePicker,
    Line,
  },
  data() {
    return {
      chartData: null,

      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            boxWidth: 10,
            align: 'start',
          }
        }
      },
      dateRange: ref([subDays(today, 7), subDays(today, 1)]),
      presetRanges,
      sitzungen: 0,
      seitenaufrufe: 0,
      conversions: 0,
      aufgerufene_seiten: {},
      ausstiegsseiten: {},
      buttonklicks: {},
      zipcodeinputs: {},
      zip_stellen: 1,
    }
  },
  created() {
    this.fetchData();
    console.log(this.ausstiegsseiten);
  },
  methods: {
    fetchData(){
      let firstDate = this.dateRange[0].toJSON().substr(0, 10);
      let lastDate = this.dateRange[1].toJSON().substr(0, 10);
      this.fetchSitzungen(firstDate, lastDate);
      this.fetchSeitenaufrufe(firstDate, lastDate);
      this.fetchConversions(firstDate, lastDate);
      this.fetchAufgerufeneSeiten(firstDate, lastDate);
      this.fetchAusstiegsseiten(firstDate, lastDate);
      this.fetchButtonklicks(firstDate, lastDate);
      this.fetchChartData(firstDate, lastDate);
      this.fetchZipData();
    },
    fetchSitzungen(firstDate, lastDate){
      axios.post(ajaxUrl, {
        request: 1,
        firstDate: firstDate,
        lastDate: lastDate,
      }).then(response => {
        this.sitzungen = response.data;
      })
    },
    fetchSeitenaufrufe(firstDate, lastDate){
      axios.post(ajaxUrl, {
        request: 2,
        firstDate: firstDate,
        lastDate: lastDate,
      }).then(response => {
        this.seitenaufrufe = response.data;
      })
    },
    fetchConversions(firstDate, lastDate){
      axios.post(ajaxUrl, {
        request: 3,
        firstDate: firstDate,
        lastDate: lastDate,
      }).then(response => {
        this.conversions = response.data;
      })
    },
    fetchAufgerufeneSeiten(firstDate, lastDate){
      axios.post(ajaxUrl, {
        request: 4,
        firstDate: firstDate,
        lastDate: lastDate,
      }).then(response => {
        this.aufgerufene_seiten = response.data;
      })
    },
    fetchAusstiegsseiten(firstDate, lastDate){
      axios.post(ajaxUrl, {
        request: 5,
        firstDate: firstDate,
        lastDate: lastDate,
      }).then(response => {
        this.ausstiegsseiten = response.data;
      })
    },
    fetchButtonklicks(firstDate, lastDate){
      axios.post(ajaxUrl, {
        request: 6,
        firstDate: firstDate,
        lastDate: lastDate,
      }).then(response => {
        this.buttonklicks = response.data;
      })
    },
    fetchChartData(firstDate, lastDate){
      axios.post(ajaxUrl, {
        request: 7,
        firstDate: firstDate,
        lastDate: lastDate,
      }).then(response => {
        //console.log(response.data.labels);
        this.chartData = {
          labels: response.data.labels,
          datasets: [
            {
              label: "Sitzungen",
              backgroundColor: '#2071B5',
              data: response.data.sitzungen,
              fill: false,
              borderColor: '#2071B5',
            } ,
              /*
            {
              label: "Seitenaufrufe",
              backgroundColor:  'rgba(12, 12, 12, 0.4)',
              data: response.data.seitenaufrufe,
              fill: false,
              borderColor:  'rgba(12, 12, 12, 0.2)',
            },*/
            {
              label: "Klickouts",
              backgroundColor:  "#FFDA00",
              data: response.data.klickouts,
              fill: false,
              borderColor:  '#FFDA00',
            }
          ]
        }
      });
    },
    fetchZipData(){
      let firstDate = this.dateRange[0].toJSON().substr(0, 10);
      let lastDate = this.dateRange[1].toJSON().substr(0, 10);
      axios.post(ajaxUrl, {
        request: 8,
        firstDate: firstDate,
        lastDate: lastDate,
        stellen: this.zip_stellen
      }).then(response => {
        this.zipcodeinputs = response.data;
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.table {
  display: table;
}

h2 {margin-top: 25px;}
.dashboard { max-width: 900px;}
.table-head {font-weight: bold; background: #2071B5; color: #FFFFFF; }
.table-row, .table-head {display: table-row;}
.table-cell {display: table-cell; padding: 10px; }
.table-row:nth-child(even) {background: #f8f8f8;}
.table-row:hover {background: #99CCFF;}
.left {text-align: left;}

.zipcode-cell {
  margin: 2px;
  padding: 10px;
  width: 100px;
}

.letter-space {
  font-stretch: expanded;
  letter-spacing: 2px;
}

.box {
  float: left;
  width: 30%;
  height: 50px;
  text-align: center;
  margin: 5px;
  line-height: 2em;
  margin-right: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-color: #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
}

.box:hover{
  background-color: #99CCFF;
}

.value {
  line-height: 1.4em;
  font-weight:bold; font-size: 2em;
}

select.zipcode {
  width: 200px;
  padding: 5px;
  margin-bottom: 15px;
}
.value, .label {text-align: left; padding: 0 10px;}

/*
.sitzungen {background-color: rgba(0, 50, 255, 0.2); }
.aufrufe {background-color: rgba(12, 12, 12, 0.2); }
.klickouts {background-color: rgba(12, 125, 12, 0.2); }

.sitzungen:hover {background-color: rgba(0, 50, 255, 0.4); }
.aufrufe:hover {background-color: rgba(12, 12, 12, 0.4); }
.klickouts:hover {background-color: rgba(12, 125, 12, 0.4); }
 */


.chartholder {max-width: 860px;}

.dp__main {max-width: 250px; margin-bottom: 10px;}
</style>
